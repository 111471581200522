// fonts
@mixin font-face($fontname, $filename: $fontname, $weight: normal, $style: normal) {
  @font-face {
    font-family: $fontname;
    src:url('../fonts/#{$filename}.eot');
    src:url('../fonts/#{$filename}.eot?#iefix') format('embedded-opentype'),
      url('../fonts/#{$filename}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
 }

 //@include font-face('PT Sans', 'PTSans-Regular', 300, normal);
 //@include font-face('PT Sans', 'PTSans-Regular', 500, normal);
 //@include font-face('PT Sans', 'PTSans-Bold', 700, bold);

 @include font-face('Montserrat', 'montserrat-light', 300, normal);
 @include font-face('Montserrat', 'montserrat-regular', 500, normal);
 @include font-face('Montserrat', 'montserrat-semibold', 600, bold);
 @include font-face('Montserrat', 'montserrat-bold', 700, bold);
